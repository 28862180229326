export const routes = {
  ErrorDisabledAccount: {
    name: 'ErrorDisabledAccount',
    title: 'Votre compte est désactivé',
    path: () => '/error/disabled-account'
  },
  ErrorUnknownUser: {
    name: 'ErrorUnknownUser',
    title: "Votre compte InclusionConnect n'est pas rattaché",
    path: () => '/error/unknown-user'
  }
} as const
