import { default as emailslpfYTqWkm2Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/emails.vue?macro=true";
import { default as integrationsGvCzte9VuoMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/integrations.vue?macro=true";
import { default as overviewIZCuEU51yQMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/overview.vue?macro=true";
import { default as indexg89S7abVE0Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsJpSMwPMpD9Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexqyjodcqFyUMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/structures/index.vue?macro=true";
import { default as index3lHw2zcDU2Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/users/[id]/index.vue?macro=true";
import { default as addyaMsXmjl8xMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/users/add.vue?macro=true";
import { default as index43vg7UqzYsMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/users/index.vue?macro=true";
import { default as adminZ9VJKtr1JWMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin.vue?macro=true";
import { default as account36TbQUZbeHMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/account.vue?macro=true";
import { default as entourageTaamaewHtrMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsWYWu0br0s1Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthrsc0YwFQm5Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoFRjqcoEd9EMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationlneddJZAh9Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdVIiFrzEThDMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printsPfOTnV2SvMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexoiS9xqub8pMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/index.vue?macro=true";
import { default as editdjNTmsb76KMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editmwL8Kv5eb2Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editHSWGJiz3WbMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addF31NfMkM7oMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editQlUKkRYGEwMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addY2md66PApMMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editJxTqchDDOuMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addSlPkFGjP6qMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editwqDiuRryOKMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as index9zahvDk4MnMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/index.vue?macro=true";
import { default as addDdhbhZpEjSMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/add.vue?macro=true";
import { default as historyk22S6sdBGgMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/history.vue?macro=true";
import { default as print8kozdDBTwPMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewfMlSyi9rbPMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/overview.vue?macro=true";
import { default as partnersA8mKCJE5MvMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/partners.vue?macro=true";
import { default as statsloAaomAedVMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/stats.vue?macro=true";
import { default as index1egCZ1lu3yMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/structure/index.vue?macro=true";
import { default as subjectsWxG0opDFP1Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/structure/subjects.vue?macro=true";
import { default as usersIufO5jgN1dMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/users.vue?macro=true";
import { default as appYrGlPvpiBZMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app.vue?macro=true";
import { default as admin0xzyjFwaGaMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/admin.vue?macro=true";
import { default as loginP0yw8G77dIMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/login.vue?macro=true";
import { default as logoutsZIvZCeiiFMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/logout.vue?macro=true";
import { default as proconnectnYJH2G5hiOMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/proconnect.vue?macro=true";
import { default as validate_45tokenP1w8IRYDCZMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/validate-token.vue?macro=true";
import { default as managerzwhj5fOrWHMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/cgu/manager.vue?macro=true";
import { default as useryFovmEXJEiMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/cgu/user.vue?macro=true";
import { default as cguFdjk5h8nY6Meta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/cgu.vue?macro=true";
import { default as declaration_45accessibiliteItIrBLtugdMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountaffgZCHFvSMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/error/disabled-account.vue?macro=true";
import { default as unknown_45user8SD7Jerb0mMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/error/unknown-user.vue?macro=true";
import { default as indexTcvpe3GFlVMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/index.vue?macro=true";
import { default as mentions_45legalesFM5vCNtIEqMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialitetaKdx9sZmGMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/politique-confidentialite.vue?macro=true";
import { default as registerePsc1iYHTBMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/register.vue?macro=true";
import { default as statistiquesOgQbwuIjJaMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/statistiques.vue?macro=true";
import { default as storiesrgM8EDB5TNMeta } from "/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminZ9VJKtr1JWMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/integrations.vue").then(m => m.default || m)
  },
  {
    name: overviewIZCuEU51yQMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewIZCuEU51yQMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/structures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/structures/[id]/subjects.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/structures/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/users/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appYrGlPvpiBZMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/account.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/entourage.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/external-organisations.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/health.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/info.vue").then(m => m.default || m)
  },
  {
    name: occupationlneddJZAh9Meta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationlneddJZAh9Meta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/occupation.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/edit/tax-household.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printsPfOTnV2SvMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: indexoiS9xqub8pMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexoiS9xqub8pMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/beneficiaries/index.vue").then(m => m.default || m)
  },
  {
    name: editdjNTmsb76KMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editdjNTmsb76KMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/budget/edit.vue").then(m => m.default || m)
  },
  {
    name: editmwL8Kv5eb2Meta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editmwL8Kv5eb2Meta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/expenses/[eid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/followups/[fid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/followups/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/help-requests/[subject]/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/help-requests/housing/add.vue").then(m => m.default || m)
  },
  {
    name: editwqDiuRryOKMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editwqDiuRryOKMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/income/[iid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/files/add.vue").then(m => m.default || m)
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/history.vue").then(m => m.default || m)
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: print8kozdDBTwPMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/historyprint/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: overviewfMlSyi9rbPMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewfMlSyi9rbPMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/overview.vue").then(m => m.default || m)
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/partners.vue").then(m => m.default || m)
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/stats.vue").then(m => m.default || m)
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/structure/index.vue").then(m => m.default || m)
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/structure/subjects.vue").then(m => m.default || m)
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/app/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: admin0xzyjFwaGaMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/admin.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginP0yw8G77dIMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutsZIvZCeiiFMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-proconnect",
    path: "/auth/proconnect",
    meta: proconnectnYJH2G5hiOMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/proconnect.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokenP1w8IRYDCZMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/auth/validate-token.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguFdjk5h8nY6Meta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/cgu.vue").then(m => m.default || m),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/cgu/manager.vue").then(m => m.default || m)
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/cgu/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibiliteItIrBLtugdMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/declaration-accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountaffgZCHFvSMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/error/disabled-account.vue").then(m => m.default || m)
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45user8SD7Jerb0mMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/error/unknown-user.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexTcvpe3GFlVMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesFM5vCNtIEqMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialitetaKdx9sZmGMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/politique-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerePsc1iYHTBMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesOgQbwuIjJaMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/statistiques.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesrgM8EDB5TNMeta || {},
    component: () => import("/build/4dc6690f-5ad8-40b3-a8cb-4e7b623a3e91/pages/stories.vue").then(m => m.default || m)
  }
]